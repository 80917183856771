* {
  box-sizing: border-box;
}
.App {
  padding: 20px;
}

.flex-container {
  display: flex;
  flex-flow: row wrap-reverse; /* to get tabindexes ordered */
  max-width: 1024px;
  margin: auto;
}

.column {
  width: 49%;
}

.flag-display {
  padding-left: 20px;
}

.country-list {
  margin-top: 15px;
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;
}

#flag {
  border: 1px solid gray;
}

.typeahead-input {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 16px; /* to prevent ios auto-zoom */
  outline: none; /* to prevent outline color on focus */
  padding: 5px;
  width: 90%;
}

.typeahead-container {
  background-color: white;
  border: 1px solid black;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 75%;
  margin: auto;
}

.width-100 {
  width: 100%;
}

.clear-input {
  align-self: center;
  text-align: center;
  width: 10%;
}

.country-dropdown {
  border-bottom: 1px solid black;
}

.country-dropdown-option {
  cursor: pointer;
  margin: 0;
  padding: 5px;
}

.country-dropdown-option:hover {
  background-color: lightgray;
}

.leaflet-container {
  border: 1px solid black;
  height: inherit;
  width: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .App {
    padding: 5px 20px;
  }

  .country-list {
    left: 0;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; /* this big because of leaflet's builtins */
  }

  .column {
    margin: 5px 0;
    width: 100%;
  }

  #flag {
    width: 100%;
  }

  .flag-display {
    padding-left: 0;
  }

  .flex-container {
    flex-direction: column;
  }

  .leaflet-container {
    height: 33vh;
    margin-top: 40px;
    width: 100%;
  }

  p {
    margin: 5px 0;
  }

  .typeahead-container {
    overflow: visible;
    width: 100%;
  }
}
